.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "Normal";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "Large";
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "Huge";
  font-size: 18px !important;
}

.ql-container {
  overflow-y: scroll;
  min-height: 500px;
  max-height: 500px;
  resize: vertical;
  background-color: "#000";
}

.fullscreen-enabled {
  background: #fff;
}

.rdw-image-imagewrapper img {
  max-width: 100%;
  object-fit: contain;
}

@media only screen and (min-width: 768px) {
  .scrollbarHidden::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbarHidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* LANDING PAGE */
@media only screen and (min-width: 768px) {
  .landingTitle {
    height: 100%;
    max-width: 90%;
    object-fit: contain;
  }

  .landingImg {
    -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
    filter: blur(5px);
    height: 80vh;
    width: 220vh;
    position: absolute;
    bottom: -47vh;
    border-radius: 40%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 768px) {
  .landingTitle {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  .landingImg {
    -webkit-filter: blur(4px); /* Safari 6.0 - 9.0 */
    filter: blur(4px);
    height: 50vh;
    width: 100vh;
    position: absolute;
    bottom: -15vh;
    border-radius: 40%;
    object-fit: cover;
  }
}
/* --------------------- */
